@tailwind base;
@tailwind components;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-Thin.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-Medium.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-Light.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-Bold.ttf) format("ttf")
      url(./assets/fonts/Poppins/Poppins-Black.ttf) format("ttf");
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.carousel-item img {
  display: block;
  /* height: auto; */
  max-width: 100%;
}

.carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 40px;
  height: 40px;
  line-height: 35px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  cursor: pointer;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width: 40px;
  z-index: 10;
}

.carousel-control.prev {
  left: 2%;
}

.carousel-control.next {
  right: 2%;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

.carousel-indicators li {
  display: inline-block;
  margin: 0 5px;
}

.carousel-bullet {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 35px;
}

.carousel-bullet:hover {
  color: #aaaaaa;
}

#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #428bca;
}

#title {
  width: 100%;
  position: absolute;
  padding: 0px;
  margin: 0px auto;
  text-align: center;
  font-size: 27px;
  color: rgba(255, 255, 255, 1);
  font-family: "Open Sans", sans-serif;
  z-index: 9999;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33),
    -1px 0px 2px rgba(255, 255, 255, 0);
}

@tailwind utilities;

.main-theme-color {
  background: radial-gradient(
    73.89% 255.09% at 80.56% 72.39%,
    #88cdff 0%,
    #005bae 100%
  );
}
.b-r-5 {
  border-radius: 5px;
}
.h-20-vh {
  height: 400px;
}
.custom-p-134 {
  padding-top: 134px;
}
.poppins-font {
  font-family: "Poppins", sans-serif;
}
.secondary-text {
  font-family: "Poppins", sans-serif;
  color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
  text-justify: inter-word;
}
.secondary-text strong {
  color: rgba(6, 38, 86, 1);
  font-size: 18px;
  font-weight: 600;
}
.secondary-text h1 strong {
  color: rgba(6, 38, 86, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.faq-text {
  font-family: "Poppins", sans-serif;
  color: rgba(108, 117, 125, 1);
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
  text-justify: inter-word;
}
.faq-text strong {
  color: rgba(6, 38, 86, 1);
  font-weight: 600;
}
.faq-text h1 strong {
  color: rgba(6, 38, 86, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}
.faq-text h2 {
  color: rgba(6, 38, 86, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.searchIcon-color {
  color: rgba(204, 204, 204, 1);
}
.div-outline-cls {
  height: 150px;
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid #88cdff;
  border-radius: 5px;
  transition: transform 250ms;

}
.div-outline-cls:hover {
  transform: scale(0.9);
  transition: transform 250ms;
}

.div-outline-cls p {
  color: #005bae;
  font-size: 18px;
  font-weight: 400;
  opacity: 100%;
}
.height-faq {
  min-height: 60vh;
}
.links-footer {
  font-size: 14px;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.links-footer-section {
  /* font-size: 16px; */
  font-weight: 400;
  cursor: pointer;
}
.links-footer:hover {
  transform: scale(1.1);

}

.hero-background {
  background-image: url("./assets/images/whiteStripesHero.svg");
  background-repeat: no-repeat;
  background-position: 25% 25%;
}
.hero-image {
  height: 50vh;
}
.h-w-56 {
  height: 51px !important;
  width: 56px !important;
}
.black-shadow {
  background-color: linearGradient(black) !important;
  filter: brightness(30%);
}

.bg-dark-blue {
  background: linear-gradient(167.07deg, #062656 3.97%, #005bae 55.74%);
}

.text-black-light {
  color: #222222;
}

.text-range-values {
  color: rgba(3, 61, 125, 1);
}

.divider-solid {
  border-top: 1px solid #f1f1f1;
}
.solid {
  border-top: 1px solid #aad8ff;
}

.d-hidden {
  display: none !important;
}

.text-blue {
  color: #005bae !important;
}

.text-blue:hover {
  color: black !important;
}
